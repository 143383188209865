import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import Sidebar from '../containers/sidebar';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
} from 'react-share';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoPinterest,
  IoLogoReddit,
} from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
  BlogDetailsContent,
} from './templates.style';

const BlogPostTemplate = (props: any) => {
  const post = props.data.markdownRemark;
  const custompost = props.data.strapiArticles



  const { edges } = props.data.allMarkdownRemark;
  const customedges  = props.data.allStrapiArticles;
  const title = custompost.title;
  const slug = custompost.slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, slug);
   
  //// custom



  
  

  



 // console.log(edges)
 // console.log(customedges)

  return (
    <Layout>
      <SEO
        title={custompost.title}
        description={custompost.title || custompost.excerpt}  // 1. desctipion
      />
      <BlogPostDetailsWrapper>
        <BlogDetailsContent>
          <PostDetails
            title={custompost.title}
            date={custompost.createdAt}
            preview={
              custompost.cover == null
                ? null
                : custompost.cover.childImageSharp.fluid
            }
            description={custompost.html}  // post.html
          />

          <BlogPostFooter>
            {custompost.tags == null ? null : (
              <PostTags className="post_tags">
                {custompost.tags.map((tag: string, index: number) => (
                  <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                    {`#${tag}`}
                  </Link>
                ))}
              </PostTags>
            )}
            <PostShare>
              <span>Sdílejte:</span>
              <FacebookShareButton url={shareUrl} quote={custompost.excerpt}>
                <IoLogoFacebook />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <IoLogoTwitter />
              </TwitterShareButton>
            
            
            </PostShare>
          </BlogPostFooter>

          <BlogPostComment>
            
          </BlogPostComment>
        </BlogDetailsContent>
        <Sidebar />
      </BlogPostDetailsWrapper>

      

   
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        cover {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1170, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            cover {
              publicURL
              childImageSharp {
                fluid(maxWidth: 370, maxHeight: 220, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }

    strapiArticles(slug: { eq: $slug }) {
      id
      excerpt
      slug
      title
      createdAt
      html
      tag
      cover {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }

    allStrapiArticles(
      limit: 3
      sort: { fields: createdAt, order: DESC }
      filter: { tag: { in: "tag" } }
    ) {
      edges {
        node {
          slug
          title
          tag
          cover {
            childImageSharp {
              fluid(maxWidth: 370, maxHeight: 220, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
