
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import _ from 'lodash';
import { TagItem } from './style';


const Customtagy = () => {
    const Data = useStaticQuery(graphql`
      query {
      
        allStrapiArticles (filter: {public: {eq: true}}){ 
        
  
          group(field: tag) {
            fieldValue
            totalCount
          }
        }
      }
    `);
  



    const ccustomTags = Data.allStrapiArticles.group;
   
    
  
    return (
    <div>
          {ccustomTags.map((tag: any) => (
            <TagItem key={tag.fieldValue}>
              <span>#</span>
              <Link to={`/tags/${_.kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue} <span>({tag.totalCount})</span>
              </Link>
            </TagItem>
          ))}   </div>
    
    )};
  
  export default Customtagy;
  