import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import _ from 'lodash';
import Customtagy from './customtagy';
import Img from 'gatsby-image';
import FeaturePost from '../../components/feature-post/feature-post';
import PromotionImage from '../../images/ad.png';
import { SidebarWrapper, SidebarWidget, WidgetTitle, TagItem } from './style';

type SidebarProps = {};

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 5
      ) {
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM [</span>]")
              title
              description
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 90, maxHeight: 90, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        group(field: frontmatter___tags) {
          totalCount
          fieldValue
        }
      }
     

      allStrapiArticles(filter: {public: {eq: true}}, sort: { fields: [createdAt], order: DESC }
       limit: 5
       ) {
        edges {
          node {
            slug
            title
            tag
            cover {
              childImageSharp {
                fluid(maxWidth: 90, maxHeight: 90, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }

        group(field: tag) {
          fieldValue
          totalCount
        }
      }
    }
  `);

  const Posts = Data.allMarkdownRemark.edges;
  const Tags = Data.allMarkdownRemark.group;
    const customPosts = Data.allStrapiArticles.edges;
  const customTags = Data.allStrapiArticles.group;
 // console.log(Tags);
  //console.log(customTags);

  return (
    <SidebarWrapper>


      <SidebarWidget>
        <WidgetTitle>Poslední příspěvky</WidgetTitle>
        {customPosts.map(({ node }: any) => {
          const title = node.title || node.slug;
          // Random Placeholder Color
          const placeholderColors = [
            '#55efc4',
            '#81ecec',
            '#74b9ff',
            '#a29bfe',
            '#ffeaa7',
            '#fab1a0',
            '#e17055',
            '#0984e3',
            '#badc58',
            '#c7ecee',
          ];
          const setColor =
            placeholderColors[
              Math.floor(Math.random() * placeholderColors.length)
            ];

          return (
            <FeaturePost
              key={node.slug}
              title={title}
              image={
                node.cover == null ? null : node.cover.childImageSharp.fluid
              }
              url={node.slug}
              tags={[node.tag]}
              placeholderBG={setColor}
            />
          );
        })}
      </SidebarWidget>

      <SidebarWidget>

        <WidgetTitle>Tagy</WidgetTitle>
       
          <Customtagy></Customtagy> 

      </SidebarWidget>
    </SidebarWrapper>
  );
};

export default Sidebar;
